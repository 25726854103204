<template>
  <div>
    <a-card :title="$t('入库订单')">
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />{{$t("返回")}}</a-button>

      <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 12 }" :wrapper-col="{ span: 12 }">
        <a-row>
          <a-col :span="24" style="width: 380px">
            <a-form-model-item prop="external_number" :label="$t('订单号')">
              <a-input v-model="form.external_number" allowClear />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="24" style="width: 380px">
            <a-form-model-item prop="warehouse" :label="$t('仓库')">
              <a-select v-model="form.warehouse" style="width: 100%" @change="onChangeWarehaou">
                <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="width: 380px">
            <a-form-model-item prop="order_type" :label="$t('订单类型')">
              <a-select v-model="form.order_type" style="width: 100%">
                <a-select-option v-for="item in orderTypeItems" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="24" style="width: 380px">
            <a-form-model-item prop="arrival_date" :label="$t('预计到货日')">
              <a-date-picker v-model="form.arrival_date" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="width: 380px">
            <a-form-model-item prop="remark" :label="$t('备注')">
              <a-input v-model="form.remark" allowClear />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-divider></a-divider>

      <div>
        <a-row gutter="16">
          <a-col :span="24" :md="8" :xl="6" style="width: 256px">
            <a-input
              v-model="materialNumber"
              :placeholder="$t('请扫描产品条码')"
              @keyup.enter.native="scanMaterial"
              allowClear
            />
          </a-col>
          <a-space>
            <a-button type="primary" @click="openMaterialModal">{{ $t("选择产品") }}</a-button>
          </a-space>
        </a-row>

        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :loading="tableLoading"
            :columns="columns"
            :data-source="receiptMaterialItems"
            :pagination="false"
          >
            <div slot="total_quantity" slot-scope="value, item">
              <a-input-number v-model="item.total_quantity" size="small"></a-input-number>
            </div>
            <div slot="batch_number" slot-scope="value, item, index">
              <a-row gutter="4">
                <a-col :span="20">
                  <a-input v-model="item.batch_number" size="small"></a-input>
                </a-col>
                <a-col :span="4">
                  <a-button type="primary" icon="plus" size="small" @click="addBatch(item, index)"></a-button>
                </a-col>
              </a-row>
            </div>
            <div slot="weight" slot-scope="value, item">
              <span v-if="item.total_quantity && item.weight">
                {{ NP.times(item.total_quantity, item.weight) }}
              </span>
            </div>
            <div slot="volume" slot-scope="value, item">
              <span v-if="item.total_quantity && item.volume">
                {{ NP.times(item.total_quantity, item.volume) }}
              </span>
            </div>

            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button type="danger" @click="removeMaterial(index)">{{ $t("删除") }}</a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="loading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <material-select-modal v-model="materialModalVisible" @select="onSelectMaterial" />
  </div>
</template>

<script>
import { warehouseOption, orderTypeOption, materialOption } from "@/api/option";
import { stockInOrderCreate } from "@/api/order";
import NP from "number-precision";
import moment from "moment";

export default {
  components: {
    MaterialSelectModal: () => import("@/components/MaterialSelectModal/index"),
  },
  data() {
    return {
      form: {
        external_number: `RK${Number(new Date())}`,
      },
      rules: {
        external_number: [
          { required: true, message: this.$t("请输入订单号"), trigger: "change" },
          { max: 32, message: this.$t("超出最大长度 (32)"), trigger: "change" },
        ],
        remark: [{ max: 256, message: this.$t("超出最大长度 (256)"), trigger: "change" }],
      },
      warehouseItems: [],
      orderTypeItems: [],
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: this.$t("名称"),
          dataIndex: "material_name",
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
        },
        {
          title: this.$t("数量"),
          dataIndex: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: this.$t("单位"),
          dataIndex: "unit",
        },
        {
          title: this.$t("批次编号"),
          dataIndex: "batch_number",
          scopedSlots: { customRender: "batch_number" },
        },
        {
          title: this.$t("规格"),
          dataIndex: "spec",
        },
        {
          title: this.$t("操作"),
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      materialNumber: "",
      materialModalVisible: false,
      receiptMaterialItems: [],
    };
  },
  methods: {
    onSelectMaterial(item) {
      let index = this.receiptMaterialItems.findIndex((_item) => _item.id == item.id);
      if (index != -1) {
        this.$message.warn(this.$t("产品已存在"));
        return;
      }

      this.receiptMaterialItems = this.$functions.insertItem(this.receiptMaterialItems, {
        id: item.id,
        material: item.id,
        material_name: item.name,
        material_number: item.number,
        total_quantity: 1,
        batch_number: `B${moment().format("YYYYMMDDHHmm")}`,
        unit: item.unit,
        weight: item.weight,
        volume: item.volume,
        spec: item.spec,
      });
    },
    removeMaterial(index) {
      let receiptMaterialItems = [...this.receiptMaterialItems];
      receiptMaterialItems.splice(index, 1);
      this.receiptMaterialItems = [...receiptMaterialItems];
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.receiptMaterialItems.length == 0) {
            this.$message.warn(this.$t("未添加产品"));
            return;
          }

          for (let index in this.receiptMaterialItems) {
            let receiptMaterialItem = this.receiptMaterialItems[index];
            if (receiptMaterialItem.total_quantity === null || receiptMaterialItem.total_quantity < 0) {
              this.$message.warn(`${this.$t("请检查第")}${parseInt(index) + 1}${this.$t("行数量")}`);
              return;
            }
            if (receiptMaterialItem.batch_number.length === 0) {
              this.$message.warn(`${this.$t("请检查第")}${parseInt(index) + 1}${this.$t("行批次编号")}`);
              return;
            }
          }

          let formData = { ...this.form, stock_in_material_items: this.receiptMaterialItems };
          this.loading = true;
          stockInOrderCreate(formData)
            .then((data) => {
              this.$message.success(this.$t("创建成功"));
              this.$router.push({ path: "/order/stock_in_order_list" });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    scanMaterial() {
      if (!this.materialNumber) {
        this.$message.warning(this.$t("请扫描产品条码"));
        return false;
      }
      this.tableLoading = true;
      materialOption({ number: this.materialNumber })
        .then((data) => {
          if (!data.results.length) {
            this.$message.warning(this.$t("没有找到相关产品，请确认扫描的产品编号！"));
            return false;
          }
          let item = data.results[0];
          let index = this.receiptMaterialItems.findIndex((_item) => _item.id == item.id);
          if (index != -1) {
            this.receiptMaterialItems = this.$functions.replaceItem(this.receiptMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              total_quantity: this.receiptMaterialItems[index].total_quantity + 1,
              batch_number: `B${moment().format("YYYYMMDDHHmm")}`,
              unit: item.unit,
            });
          } else {
            this.receiptMaterialItems = this.$functions.insertItem(this.receiptMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              total_quantity: 1,
              batch_number: `B${moment().format("YYYYMMDDHHmm")}`,
              unit: item.unit,
            });
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    addBatch(item, index) {
      let new_item = { ...item };
      new_item["batch_number"] = `B${moment().format("YYYYMMDDHHmm")}`;
      this.receiptMaterialItems.splice(index, 0, new_item);
    },
    openMaterialModal() {
      this.materialModalVisible = true;
    },
  },
};
</script>

<style scoped></style>
